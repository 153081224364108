$black: #000;
$blue: #0079df;
$gray0: #45424B;
$gray1: #737373;
$gray4: #dadada;
$green: #00bc39;
$red: #B40B0B;
$white: #fff;

$scrubTiming: 133ms;

$Lato-MediumItalic: 'Lato-MediumItalic', Helvetica, Arial, sans-serif;
$Lato-Semibold: 'Lato-Semibold', Helvetica, Arial, sans-serif;
$Lato-Bold: 'Lato-Bold', Helvetica, Arial, sans-serif;