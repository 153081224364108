@import 'vars';

.bottom {
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 100vw;
  background-color: $white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
  button {
    border: none;
    background: none;
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  $transition-timing: 500ms;
  .bottom {
    height: 75px;
    transition: height $transition-timing;
    &-small-tall {
      position: relative;
      padding: 20vh 11vw 0;
      text-align: center;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      opacity: 0;
      transition-duration: $transition-timing;
      transition-timing-function: ease-out;
      .bottom-close {
        position: fixed;
        top: 3vw;
        right: 3vw;
        height: 13vw;
        width: 13vw;
      }
      &-close {
        width: 40%;
        height: 40%;
      }
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        &.bottom-app-store {
          height: 17vw;
        }
      }
      .logo-big-h {
        height: 28vw;
        margin-bottom: 3vw;
      }
      .bottom-small-logo {
        height: 11vw;
        margin-bottom: 7vw;
      }
      h1 {
        margin-bottom: 4vw;
      }
      h2 {
        margin-bottom: 2vw;
      }
      &-app-store {
        margin-bottom: 7vw;
        padding-bottom: 7vw;
        border-bottom: solid 1px $gray4;
      }
    }

    &-small-short {
      position: fixed;
      bottom: 0;
      height: 75px;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: $transition-timing;
      transition-timing-function: ease;
    }
    &-small-item {
      position: relative;
      height: 40px;
    }
    &-small-dots {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      height: 4px;
    }
    &-small-logo {
      margin-right: 50px;
    }

    &-full {
      height: 100vh;
      .bottom-small-short {
        bottom: -75px;
        opacity: 0;
        .bottom-small-dots {
          display: none;
        }
      }
      .bottom-small-tall {
        height: 100vh;
        opacity: 1;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .bottom {
    .logo-big-h,
    .bottom-close,
    &-small-short {
      display: none;
    }
    &-small-logo {
      margin-bottom: 10px;
      width: 146px;
      height: 47px;
    }
    &-small-tall {
      display: flex;
      justify-content: center;
      padding: 30px;
      .bottom-app-store {
        width: 170px;
      }
      > div {
        width: 33%;
        max-width: 400px;
        padding: 0 30px;
        &:nth-of-type(1) {
          text-align: right;
        }
        &:nth-of-type(2) {
          display: flex;
          order: 3;
          align-items: center;
        }
        &:nth-of-type(1),
        &:nth-of-type(2) {
          flex-grow: 1;
        }
        &:nth-of-type(3) {
          order: 2;
          border-left: solid 1px $gray4;
          border-right: solid 1px $gray4;
        }
        ol,
        h1 {
          margin-bottom: 0;
        }
      }

    }

  }
}
