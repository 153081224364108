@import 'vars';

.slider {
  position: relative;
  &-input {
    position: relative;
    z-index: 3;
    margin: 0;
    width: 100%;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    transition-duration: $scrubTiming;
    opacity: 1;
    &.mod-hidden {
      opacity: 0;
    }
  }
  &-input::-webkit-slider-thumb {
    position: relative;
  }
  &-progress,
  &-progress-under {
    position: absolute;
  }
  &-progress {
    background-color: $green;
    position: absolute;
    z-index: 2;
  }
  &-progress-under {
    position: absolute;
    background-color: $gray0;
    z-index: 1;
    width: 100%;
    transition-duration: $scrubTiming;
  }
  &.mod-scrubbing-slider {
    .slider {
      &-progress {
        z-index: 12;
      }
      &-progress-under {
        z-index: 11;
        background-color: $white;
      }
      &-input {
        z-index: 15;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  $buttonSize: 8vw;
  .slider {
    width: 84vw;
    height: 8vw;
    top: -3vw;
    &-input {
      width: 100%;
      height: 8vw;
    }
    &-input::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      position: relative;
      width: $buttonSize;
      height: $buttonSize;
      border-radius: $buttonSize;
      background-color: $green;
      border: solid .53vw $white;
      box-shadow: 0 .8vw 1.8vw rgba(0, 0, 0, .15);
      transform-origin: center;
      transition-duration: $scrubTiming;
    }
    &-progress,
    &-progress-under {
      height: .8vw;
      top: 50%;
      transform: translateY(-50%);
    }
    &.mod-scrubbing-slider {
      .slider-input::-webkit-slider-thumb {
        position: relative;
        transform: scale(1.5);
        box-shadow: 0 0 13vw rgba(119, 223, 159, .9);
        border-width: .4vw;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  $buttonSize: 30px;
  @mixin sliderButton {
    width: $buttonSize;
    height: $buttonSize;
    border-radius: $buttonSize;
    background-color: $green;
    border: solid 2px $white;
    box-shadow: 0 3px 7px rgba(0, 0, 0, .15);
    transform-origin: center;
  }
  .slider {
    width: 315px;
    height: 18px;
    top: -10px;
    &-input {
      height: 3px;
    }
    &-progress,
    &-progress-under {
      top: 12px;
      height: 3px;
    }
    &-input::-webkit-slider-thumb {
      @include sliderButton;
      -webkit-appearance: none;
      appearance: none;
    }
    &-input::-moz-range-thumb {
      @include sliderButton;
    }
    &.mod-scrubbing-slider {
      .slider-input {
        outline: 0;
      }
    }
  }
}
