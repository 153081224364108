@font-face {
  font-family: 'Lato-MediumItalic';
  src: local('Lato-MediumItalic'),
    url(../fonts/Lato-MediumItalic.ttf) format('ttf'),
    url(../fonts/Lato-MediumItalic.woff) format('woff');
}

@font-face {
  font-family: 'Lato-Semibold';
  src: local('Lato-Semibold'),
    url(../fonts/Lato-Semibold.ttf) format('ttf'),
    url(../fonts/Lato-Semibold.woff) format('woff');
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'),
    url(../fonts/Lato-Bold.ttf) format('ttf'),
    url(../fonts/Lato-Bold.woff) format('woff');
}