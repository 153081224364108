@import 'vars';

* {
  box-sizing: border-box;
}
.App {
  overflow: auto;
}

body,
html {
  height: 100%;
  width: 100%;
}
body {
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  color: $blue;
  &:visited,
  &:active {
    color: $blue;
  }
}

h1,
h2,
p {
  color: $gray0;
  font-weight: normal;
  font-family: $Lato-MediumItalic;
}


ol li {
  text-align: left;
  font-family: $Lato-Semibold;
  color: $gray1;
  &:last-child {
    margin-bottom: 0;
  }
}

.mouse-user {
  * {
    outline: 0;
  }
}

.background-hs {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $white;
  &-overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, .85);
  }
  .floater {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

.light-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .8);
}

.lds-roller.mod-firstLoad {
  display: block;
  div::after {
    background: $gray1;
  }
}

@media only screen and (max-width: 768px) {
  body {
    background-image: url('../images/mess-sm.jpg');
  }
  h1 {
    line-height: 8.5vw;
    font-size: 6.5vw;
    margin: 0 0 5vw;
  }
  h2 {
    font-size: 4.8vw;
    margin: 0 0 4vw;
  }
  ol {
    padding-left: 4.3vw;
    margin-bottom: 15vw;
  }
  li {
    line-height: 5.6vw;
    font-size: 4.2vw;
    margin-bottom: 5vw;
  }
  .main-logo {
    display: none;
  }
  .lds-roller.mod-firstLoad {
    margin: 38vw auto 0;
  }
}

@media only screen and (min-width: 769px) {
  body {
    background-image: url('../images/mess-lg.jpg');
  }
  h1 {
    line-height: 32px;
    font-size: 24px;
    margin: 0 0 15px;
  }
   h2 {
    font-size: 18px;
    margin: 0 0 15px;
  }
  ol {
    padding-left: 17px;
    margin-bottom: 15px;
  }
  li {
    line-height: 20px;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .main-logo {
    display: block;
    width: 146px;
    height: 47px;
    margin: 30px auto;
  }
  .lds-roller.mod-firstLoad {
    margin: 155px auto 0;
  }
}