@import 'vars';
@import 'spinner';

.player {
  background-color: $white;
  margin: 0 auto;
  &-top {
    position: relative;
    border-radius: 10px 10px 0 0;
    &.mod-full-titles {
      h1,
      h2 {
        -webkit-line-clamp: 5;
      }
      .player-meta {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &-artwork,
  &-meta,
  &-buffering {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &-share {
    display: block;
    &-notice {
      height: 0;
      opacity: 0;
      transition-duration: 333ms;
      // transition: opacity 333ms height 333ms;
      overflow: hidden;
      &.mod-notice-showing {
        opacity: 1;
      }
    }
  }
  &-artwork {
    z-index: 1;
    overflow: hidden;
    > img {
      height: 100%;
      width: 100%;
    }
  }
  &-scrub {
    &-progress,
    &-otherinfo {
      position: absolute;
      left: 50%;
      display: none;
      transform: translateX(-50%);
      font-family: $Lato-Semibold;
      color: $white;
      z-index: 11;
    }
    &-otherinfo {
      > div {
        text-align: center;
        span {
          display: block;
          font-family: $Lato-Semibold;
          color: $white;
        }
      }
    }
  }
  &-buffering {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    opacity: 0;
    overflow: hidden;
    color: white;
    background-color: rgba($black, .5);
    transition: opacity 150ms linear;
    &.mod-spinner-showing {
      opacity: 1;
    }
  }
  &-meta {
    z-index: 3;
    display: flex;
    align-items: flex-end;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 25%, rgba(0,0,0,0.32) 50%, #000000 100%);
    transition-duration: 200ms;
    h1,
    h2 {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      color: $white;
    }
    h1 {
      margin-bottom: 2px;
      font-family: $Lato-Semibold;
      -webkit-line-clamp: 2;
    }
    h2 {
      margin-bottom: 0;
      -webkit-line-clamp: 3;
    }
  }
  &-position {
    font-family: $Lato-Bold;
    color: $gray0;
    text-align: center;
  }
  &-bottom {
    button {
      padding: 0;
      border: none;
      background: none;
    }
  }
  &-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    &-back,
    &-forward {
      display: flex;
      align-items: center;
      font-family: $Lato-Semibold;
      color: $gray0;
      > img {
        position: relative;
      }
    }
    &-forward {
      > img {
        transform: rotate(180deg);
      }
    }
    &-playpause {
      @keyframes spin {
        from {transform: rotate(0)}
        to {transform: rotate(360deg)}
      }
      position: relative;
      &-button.mod-spinning {
        animation: spin 10s infinite linear;
      }
      &-symbol {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.player.mod-scrubbing-layout {
  @keyframes overlayIn {
    from {opacity: 0}
    to {opacity: 1}
  }
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.90) 59%, #000000 100%);
    animation: overlayIn $scrubTiming forwards;
  }
  .player {
    &-position,
    &-controls,
    &-share {
      opacity: 0;
      display: none;
      transition-duration: $scrubTiming;
    }
    &-scrub {
      &-progress {
        display: block;
      }
      &-otherinfo {
        display: flex;
      }
    }
    &-meta {
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  $playerWidth: 84vw;
  $radius: 2.666667vw;
  .player {
    border-radius: $radius;
    width: $playerWidth;
    margin-top: 8vw;
    margin-bottom: 30vw;
    box-shadow:
      0 .8vw 1.3333vw -.8vw rgba($black, .25),
      0 .5333vw 2.66667vw rgba($black, .15);
    &-top {
      width: $playerWidth;
      height: $playerWidth;
    }
    &-artwork,
    &-buffering,
    &-meta {
      border-radius: $radius $radius 0 0;
    }
    &-meta {
      padding: 8vw 5.33333vw;
    }
    &-bottom {
      height: 62.66667vw;
    }
    &-position {
      font-size: 8.5333vw;
      margin-top: 3.5vw;
    }
    &-scrub {
      &-progress {
        font-size: 22vw;
        top: 20%;
      }
      &-otherinfo {
        top: 62%;
        > div {
          font-size: 8vw;
          span {
            font-size: 3.2vw;
          }
          &:first-of-type {
            margin-right: 20vw;
          }
        }
      }
    }
    &-controls {
      margin-top: 5vw;
      &-back,
      &-forward {
        line-height: 6.4vw;
        font-size: 6.4vw;
        height: 13vw;
        > img {
          top: .15vw;
          height: 2.6667vw;
        }
      }
      &-back {
        > img {
          margin-right: 2.5vw;
        }
      }
      &-forward {
        > img {
          margin-left: 2.5vw;
        }
      }
      &-playpause {
        $size: 23.5vw;
        height: $size;
        width: $size;
        margin: 0 6vw;
        &-button {
          height: $size;
          width: $size;
        }
        &-symbol {
          height: 9vw;
          left: 50.5%;
          &.mod-play {
            height: 9vw;
          }
          &.mod-pause {
            height: 8vw;
          }
        }
      }
    }
    &-share {
      height: 13vw;
      width: 37vw;
      margin: 5.5vw auto;
      &-notice {
        margin-top: 2vw;
        &.mod-notice-showing {
          height: 3vw;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  $playerWidth: 315px;
  $radius: 10px;
  button {
    cursor: pointer;
  }
  .player {
    border-radius: $radius;
    width: $playerWidth;
    margin-bottom: 360px;
    box-shadow:
      0 3px 5px -3px rgba($black, .25),
      0 2px 10px rgba($black, .15);
    &-top {
      height: $playerWidth;
      width: $playerWidth;
    }
    &-artwork,
    &-buffering,
    &-meta {
      border-radius: $radius $radius 0 0;
      transform-origin: 50% 0;
    }
    &-meta {
      padding: 30px 20px;
    }
    &-bottom {
      height: 235px;
    }
    &-position {
      font-size: 32px;
      margin-top: 20px;
    }
    &-scrub {
      &-progress {
        font-size: 100px;
        top: 30%;
      }
      &-otherinfo {
        top: 70%;
        div {
          font-size: 30px;
          span {
            font-size: 12px;
          }
          &:first-of-type {
            margin-right: 64px;
          }
        }
      }
    }
    &-controls {
      margin-top: 20px;
      &-back,
      &-forward {
        line-height: 29px;
        font-size: 24px;
        height: 50px;
        > img {
          height: 10px;
        }
      }
      &-back {
        > img {
          margin-right: 10px;
        }
      }
      &-forward {
        > img {
          margin-left: 10px;
        }
      }
      &-playpause {
        margin: 0 25px;
      }
    }
    &-share {
      height: 50px;
      width: 140px;
      margin: 20px auto;
      &-notice {
        margin-top: 7px;
        &.mod-notice-showing {
          height: 12px;
        }
      }
    }
  }
  .player.mod-scrubbing-layout {
    background-color: transparent;
    box-shadow: none;
    .player {
      &-artwork {
        transform: scale(1.5);
        transition-duration: $scrubTiming;
        border-radius: 250px;
        border: solid 1px rgba(0, 0, 0, .1)
      }
      &-bottom,
      &-buffering {
        display: none;
      }
    }
  }
}